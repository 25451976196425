import React, { useEffect, useState } from 'react';

interface MobileOnlyProps {
	children: React.ReactNode;
}

const MobileOnly: React.FC<MobileOnlyProps> = (props) => {
	const [width, setWidth] = useState<number>(0);

	const checkWindowWidth = () => {
		setWidth(document.body.clientWidth);
	};

	useEffect(() => {
		checkWindowWidth();
		window.addEventListener('resize', checkWindowWidth);
		return window.removeEventListener('resize', checkWindowWidth);
	}, []);

	if (width > 575) {
		return <></>;
	}
	return <>{props.children}</>;
};

export default React.memo(MobileOnly);
