import Image from 'next/image';
import React, { FC, ReactNode } from 'react';
import Trans from '../Common/Trans';

type SloganContainerProps = {
	isLocation?: boolean;
	description?: string;
	vert?: string;
	img: string;
	sloganKey?: string;
	children: ReactNode;
};
const SloganContainer: FC<SloganContainerProps> = (props) => {
	const { description, vert, children, sloganKey, img, isLocation } = props;

	return (
		<div id="slogan-container">
			<div className="body">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-12 order-first text-center">{children}</div>
						<div className="col-lg-6 text-center">
							{isLocation ? (
								<h2 className="slogan">{description} </h2>
							) : (
								<p className="slogan">
									<Trans i18nKey={sloganKey} />
								</p>
							)}
						</div>
						<div className="col-lg-6 text-center order-first order-lg-2">
							<Image
								width={405}
								height={242}
								alt={vert as string}
								className="img-fluid slogan-container-img"
								src={img}
								style={{ objectFit: 'contain' }}
								priority={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default React.memo(SloganContainer);
