import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import Helper from '../../modules/Helper';

type VertTitleProps = {
	i18nKey: string;
	enabledVert: string;
	useHeadline?: boolean;
	title?: string;
};

type VertTitleState = {
	title: string;
	size: number | string;
};

class VertTitle extends React.Component<VertTitleProps, VertTitleState> {
	constructor(props: VertTitleProps) {
		super(props);
		this.state = {
			title: '',
			size: 60,
		};
	}

	componentDidMount() {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', this.check);
		}
		this.checkTitleSize(this.props.enabledVert);
	}

	componentDidUpdate(prevProps: VertTitleProps) {
		if (prevProps.enabledVert !== this.props.enabledVert || prevProps.i18nKey !== this.props.i18nKey) {
			this.checkTitleSize(this.props.enabledVert);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.check);
	}

	check = () => {
		this.checkTitleSize();
	};

	checkTitleSize = (vert?: string) => {
		const parsed = Helper.parseQueryString(window.location.search, true);

		let title = i18n.t(this.props.i18nKey);

		if (parsed.title && parsed.title.length <= 80) {
			// @ts-ignore
			title = parsed.title;
		}

		let size = 60;
		if (title.length >= 45) {
			const diff = (45 - title.length) * -1;
			// @ts-ignore
			size = 60 - parseInt(diff);
		}
		if (size < 45) {
			size = 45;
		}
		// @ts-ignore
		const w = parseInt(window.innerWidth);
		if (w < 1000 && w > 900) {
			size -= 10;
		}
		if (w <= 900) {
			size -= 15;
		}
		if (w < 800) {
			size -= 5;
		}
		if (w < 700) {
			size -= 5;
		}
		if (w < 600) {
			size -= 6;
		}
		if (w < 500) {
			size -= 4;
		}
		const enabledVert = vert || this.props.enabledVert;
		if (w < 360 && enabledVert === 'cars') {
			size -= 3;
		}
		if (enabledVert === 'cars' && size < 26) {
			size = 24;
		}
		if (enabledVert === 'flights' && size < 30) {
			size = 30;
		}

		this.setState({
			title,
			size: size + 'px',
		});
	};

	render() {
		if (this.props.useHeadline) {
			return (
				<h1 style={{ fontSize: this.state.size }} className="title">
					{this.props.title || this.state.title}
				</h1>
			);
		}
		return (
			<div style={{ fontSize: this.state.size }} className="title">
				{this.props.title || this.state.title}
			</div>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	enabledVert: state.base.enabledVert,
});
export default connect(mapStateToProps, {})(VertTitle);
