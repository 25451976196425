import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';

type SupportProps = {
	showPhoneNumber?: boolean;
	phoneNumber?: string;
	countryCode?: string;
};

const Support: FC<SupportProps> = (props) => {
	const { showPhoneNumber, phoneNumber } = props;
	const { t } = useTranslation();
	if (!showPhoneNumber) return <></>;

	return (
		<div id="support">
			<p
				dangerouslySetInnerHTML={{
					__html: i18n.t('main.flights.supportText'),
				}}
			/>
			<a href={`tel:${phoneNumber}`} className="btn btn-primary btn-blue">
				<span style={{ verticalAlign: 'middle' }}>
					<i style={{ fontSize: '24px', marginRight: '2px' }} className="icon-phone" />
				</span>{' '}
				<span>{t('keywords.call').toUpperCase()}:</span>
				<span className="color-yellow"> {phoneNumber}</span>
			</a>
			<div className="clearfix" />
			<br />
			<br />
		</div>
	);
};
// @ts-ignore
const mapStateToProps = (state) => ({
	countryCode: state.base.countryCode,
	showPhoneNumber: state.base.showPhoneNumber,
	phoneNumber: state.base.phoneNumber,
});
export default React.memo(connect(mapStateToProps, {})(Support));
